<style lang="scss" scoped>
@import "assets/scss/default.scss";
</style>

<template>
	<Header></Header>
	<div id="globalContainer">
		<Navbar :navLinks="navLinks"></Navbar>
		<main v-auto-animate>
			<UContainer :ui="ui">
				<slot/>
			</UContainer>
		</main>
	</div>
</template>

<script setup>
const ui = {
	base: "h-full",
	padding: "",
	constrained: ""
};

const navLinks = [
	{ url: "/admin", name: "Accueil", icon: "isax isax-element-4" },
	{ url: "/admin/orders", name: "Commandes", icon: "isax isax-document-copy" },
	{ url: "/admin/transactions", name: "Transactions", icon: "isax isax-repeat" },
	{ url: "/admin/accounts", name: "Comptes client", icon: "isax isax-buildings4" }
]
</script>

<script>
export default {
	name: "Default",
	mounted() {
		const { $events } = useNuxtApp();
		// Loader
		$events.on("loading", ([state, text, fullHeight]) => {
			this.loadingText = text || "loader.loading";
			this.loading = state;
			this.fullHeight = fullHeight;

			// If loading add overflow hidden
			if (state) document.body.classList.add("overflow-hidden");
			else document.body.classList.remove("overflow-hidden");
			// Move the scroll to the top
			window.scrollTo(0, 0);
		});
	}
};
</script>
